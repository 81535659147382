/* line 13, scss/80-themes/Saunders/70-modules/listingmarketdatasalestransactionschart/module.scss */
.m-marketdata-sales-transactions {
	margin-bottom: 20px;
	background-color: rgb(var(--color-global-widget));
}

/* line 17, scss/80-themes/Saunders/70-modules/listingmarketdatasalestransactionschart/module.scss */
.m-marketdata-sales-transactions .md-chart {
	background: transparent;
	padding: 0;
	border: none;
}

/* line 24, scss/80-themes/Saunders/70-modules/listingmarketdatasalestransactionschart/module.scss */
.marketdata-sales-transactions__chart-title {
	margin-bottom: 10px;
	text-transform: capitalize;
}

/* line 28, scss/80-themes/Saunders/70-modules/listingmarketdatasalestransactionschart/module.scss */
.marketdata-sales-transactions__chart-title--colored {
	color: rgb(var(--color-global-text));
}

/* line 33, scss/80-themes/Saunders/70-modules/listingmarketdatasalestransactionschart/module.scss */
.sales-transactions-chart-graph {
	padding-right: 20px;
	width: 100% !important;
	box-sizing: border-box;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .sales-transactions-chart-graph {
	padding-right: initial;
	padding-left: 20px;
}

/*# sourceMappingURL=../../../../../true */